<template>
    <el-container class="content">
        <el-aside width="200px">
            <el-menu :default-active="activeIndex" class="menu" router @open="handleOpen" :default-openeds="defaultOpeneds" unique-opened>
                <el-submenu index="1" title>
                    <template slot="title">
                        <span>Member Center</span>
                    </template>
                    <el-menu-item index="index">Welcome page</el-menu-item>
                    <el-menu-item index="info">Personal Information</el-menu-item>
                    <el-menu-item index="security">Account security</el-menu-item>
                    <el-menu-item index="delivery_address">Receiving address</el-menu-item>
                    <el-menu-item index="collection">My attention</el-menu-item>
                    <el-menu-item index="footprint">My Tracks</el-menu-item>
                </el-submenu>
                <el-submenu index="2" title>
                    <template slot="title">
                        <span>Trading Center</span>
                    </template>
                    <el-menu-item index="order_list">My Orders</el-menu-item>
                    <el-menu-item index="activist">Refund / After sales</el-menu-item>
                    <template v-if="is_verify == 1">
                        <el-menu-item index="verification">Verification desk</el-menu-item>
                        <el-menu-item index="verification_list">Write off record</el-menu-item>
                    </template>
                </el-submenu>
                <el-submenu index="3" title>
                    <template slot="title">
                        <span>Account center</span>
                    </template>
                    <el-menu-item index="account">Account balance</el-menu-item>
                    <el-menu-item index="withdrawal">Withdrawal record</el-menu-item>
                    <el-menu-item index="my_coupon">My Coupons</el-menu-item>
                    <el-menu-item index="my_point">My points</el-menu-item>
					<el-menu-item index="account_list">Account list</el-menu-item>
                    <!-- <el-menu-item index="level">会员等级</el-menu-item> -->
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-main class="member">
            <transition name="slide"><router-view /></transition>
        </el-main>
    </el-container>
</template>
<script>
    import { checkisverifier } from "@/api/order/verification"
    export default {
        name: "home",
        components: {},
        data: () => {
            return {
                defaultOpeneds: ["1"],
                activeIndex: "index",
                is_verify: 1
            }
        },
        created() {
            this.activeIndex = this.$route.meta.parentRouter ||this.$route.path.replace("/member/", "")
            this.checkIsVerifier()
        },
        methods: {
            handleOpen(key, keyPath) {
                this.defaultOpeneds = keyPath
            },
            checkIsVerifier() {
                checkisverifier()
                    .then(res => {
                        if (res.data) {
                            this.is_verify = 1
                        }
                    })
                    .catch(err => {
                        this.is_verify = 0
                    })
            }
        },
        watch: {
            $route(curr) {
				this.activeIndex = curr.meta.parentRouter||this.$route.path.replace("/member/", "");
            }
        }
    }
</script>
<style lang="scss" scoped>
    .menu {
        min-height: 730px;
    }
    .content {
        display: flex !important;
        margin: 20px 0;
    }
    .member {
        margin-left: 15px;
    }
</style>
